// Carousel
// Based on https://github.com/nolimits4web/swiper
// stylelint-disable


// Navigation (Prev / Next) buttons

.pagination {
  .swiper-button-disabled,
  .swiper-button-disabled:disabled {
    background-color: transparent !important;
    box-shadow: none !important;
    pointer-events: none;
  }
}

.swiper {
  .btn-prev:not(.position-static),
  .btn-next:not(.position-static) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }
  .btn-prev {
    left: $spacer * 1.25;
  }
  .btn-next {
    right: $spacer * 1.25;
  }

  &.swiper-edge {
    .swiper-slide {
      @include media-breakpoint-down(sm) {
        width: 80%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .swiper-nav-onhover {
    .btn-prev,
    .btn-next,
    .swiper-pagination {
      opacity: 0 !important;
    }
    &:hover {
      .btn-prev,
      .btn-next,
      .swiper-pagination {
        opacity: 1 !important;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .swiper-nav-onhover {
    .btn-prev,
    .btn-next {
      display: none !important;
    }
  }
}


// Pagination

// Bullets

.swiper-pagination {
  --#{$prefix}carousel-pagination-bullet-size: #{$carousel-bullet-size};
  --#{$prefix}carousel-pagination-bullet-spacer: #{$carousel-bullet-spacer};
  --#{$prefix}carousel-pagination-bullet-border-color: #{$carousel-bullet-border-color};
  --#{$prefix}carousel-pagination-bullet-border-width: #{$carousel-bullet-border-width};
  --#{$prefix}carousel-pagination-bullet-border-radius: #{$carousel-bullet-border-radius};
  --#{$prefix}carousel-pagination-bullet-bg: #{$carousel-bullet-bg};
  --#{$prefix}carousel-pagination-bullet-active-border-color: #{$carousel-bullet-active-border-color};
  --#{$prefix}carousel-pagination-bullet-active-bg: #{$carousel-bullet-active-bg};

  display: flex;
  justify-content: center;
  align-items: center;
  bottom: $spacer * 1.25;
  padding: {
    top: $spacer * .25;
    bottom: $spacer * .25;
  }

  &.swiper-pagination-lock { display: none; }

  // Bullets light
  &.swiper-pagination-light {
    --#{$prefix}carousel-pagination-bullet-border-color: #{$carousel-light-bullet-border-color};
    --#{$prefix}carousel-pagination-bullet-bg: #{$carousel-light-bullet-bg};
    --#{$prefix}carousel-pagination-bullet-active-border-color: #{$carousel-light-bullet-active-border-color};
    --#{$prefix}carousel-pagination-bullet-active-bg: #{$carousel-light-bullet-active-bg};
  }
}

.swiper-pagination-bullet {
  position: relative;
  width: var(--#{$prefix}carousel-pagination-bullet-size);
  height: var(--#{$prefix}carousel-pagination-bullet-size);
  transition: $carousel-bullet-transition;
  border: var(--#{$prefix}carousel-pagination-bullet-border-width) solid var(--#{$prefix}carousel-pagination-bullet-border-color);
  @include border-radius(var(--#{$prefix}carousel-pagination-bullet-border-radius));
  background-color: var(--#{$prefix}carousel-pagination-bullet-bg);
  opacity: 1;

  &:focus {
    outline: none;
  }
}

.swiper-pagination-bullet-active {
  border-color: var( --#{$prefix}carousel-pagination-bullet-active-border-color);
  background-color: var( --#{$prefix}carousel-pagination-bullet-active-bg);
  transform: scale(2);
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: $spacer * 1.5;
  .swiper-pagination-bullet {
    margin: {
      right: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * .5);
      left: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * .5);
    }
  }
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-vertical {
  flex-direction: column;
  right: $spacer * 1.25;
  .swiper-pagination-bullet {
    margin: {
      top: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * .5);
      bottom: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * .5);
    }
  }
}


// Thumbnails

.swiper-thumbnails {
  --#{$prefix}carousel-thumbnail-width: #{$carousel-thumbnail-width};
  --#{$prefix}carousel-thumbnail-border-width: #{$carousel-thumbnail-border-width};
  --#{$prefix}carousel-thumbnail-border-radius: #{$carousel-thumbnail-border-radius};
  --#{$prefix}carousel-thumbnail-border-color: #{$carousel-thumbnail-border-color};
  --#{$prefix}carousel-thumbnail-opacity: #{$carousel-thumbnail-opacity};
  --#{$prefix}carousel-thumbnail-hover-opacity: #{$carousel-thumbnail-hover-opacity};
  --#{$prefix}carousel-thumbnail-active-border-color: #{$carousel-thumbnail-active-border-color};
  --#{$prefix}carousel-thumbnail-active-opacity: #{$carousel-thumbnail-active-opacity};
  --#{$prefix}carousel-thumbnail-spacer: #{$carousel-thumbnail-spacer};

  position: static;
  display: flex;
  flex-wrap: wrap;
  gap: var(--#{$prefix}carousel-thumbnail-spacer);
  margin-top: var(--#{$prefix}carousel-thumbnail-spacer);

  @include media-breakpoint-down(md) {
    --#{$prefix}carousel-thumbnail-spacer: #{$carousel-thumbnail-spacer * .5};
  }
  @include media-breakpoint-down(sm) {
    --#{$prefix}carousel-thumbnail-width: #{calc(33.333% - ($carousel-thumbnail-spacer * .33333))};
    flex-wrap: wrap !important;
    // justify-content: center;
  }
}

.swiper-thumbnail {
  width: var(--#{$prefix}carousel-thumbnail-width);
  border: var(--#{$prefix}carousel-thumbnail-border-width) solid var(--#{$prefix}carousel-thumbnail-border-color);
  overflow: hidden;
  @include border-radius(var(--#{$prefix}carousel-thumbnail-border-radius));

  &:last-child {
    margin-right: 0 !important;
  }

  > img {
    transition: opacity .25s ease-in-out;
    @include border-radius(calc(var(--#{$prefix}carousel-thumbnail-border-radius) - .25rem));
    opacity: var(--#{$prefix}carousel-thumbnail-opacity);
  }

  &:hover > img {
    opacity: var(--#{$prefix}carousel-thumbnail-hover-opacity);
  }

  &.active {
    border-color: var(--#{$prefix}carousel-thumbnail-active-border-color);

    > img {
      opacity: var(--#{$prefix}carousel-thumbnail-active-opacity);
    }
  }
}

.swiper-thumbnails-circle .swiper-thumbnail,
.swiper-thumbnails-circle .swiper-thumbnail > img {
  border-radius: 50%;
}
// stylelint-enable
