// Extend default Bootstrap's .nav, .nav-item, .nav-link classes

.nav {
  --#{$prefix}nav-link-line-height: #{$nav-link-line-height};
  --#{$prefix}nav-link-active-color: #{$nav-link-active-color};

  flex-direction: row;
  gap: 0;
}

.nav-link {
  display: flex;
  align-items: center;

  &.show {
    color: var(--#{$prefix}nav-link-hover-color);
  }

  &.active {
    color: var(--#{$prefix}nav-link-active-color);
  }

  &.disabled,
  &:disabled {
    color: var(--#{$prefix}nav-link-disabled-color);
  }
}
