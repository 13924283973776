// Navbar
// Sticky navbar

.navbar {
  background-color: rgba($white, .02);
  backdrop-filter: blur(20px);
  @include media-breakpoint-down(lg) {
    --#{$prefix}navbar-padding-y: #{$navbar-padding-y * .5};
    // background-color: $dark;
  }
}

.navbar-stuck {
  // stylelint-disable declaration-no-important
  position: fixed !important;
  // stylelint-enable declaration-no-important
  top: 0;
  left: 0;
  z-index: $zindex-fixed + 1;
  width: 100%;
  // background-color: $dark;
  animation: navbar-show .25s ease-in-out;
}

.navbar.position-absolute {
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
}

@keyframes navbar-show {
  from {
    transform: translateZ(0) translateY(-100%);
  }
  to {
    transform: translateZ(0) translateY(0);
  }
}


// Navbar Toggler: Hamburger
// --------------------------------------------------
// Based on https://github.com/jonsuh/hamburgers
// stylelint-disable

// Base styles

.navbar-toggler {
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  display: inline-block;
  position: relative;
  width: $navbar-toggler-bar-width + $navbar-toggler-padding-x * 2;
  height: $navbar-toggler-bar-height * 3 + $navbar-toggler-bar-spacing * 2 + $navbar-toggler-padding-y * 2;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:focus {
    box-shadow: unset;
  }
}

.navbar-toggler-icon {
  display: block;
  top: 50%;
  margin-top: $navbar-toggler-bar-height * -2;

  &,
  &::before,
  &::after {
    width: $navbar-toggler-bar-width;
    height: $navbar-toggler-bar-height;
    border-radius: $navbar-toggler-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: ease;
    background-color: $white;
  }

  &::before,
  &::after {
    display: block;
    content: '';
  }

  &::before {
    top: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
  }

  &::after {
    bottom: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
  }
}


// Navbar toggler animation

.navbar-toggler {
  .navbar-toggler-icon {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease,
                  opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease,
                  transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &[aria-expanded=true] {
    .navbar-toggler-icon {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease,
                    opacity 0.075s 0.12s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
                    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
// stylelint-enable
