// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-hover-color and $btn-padding-y-sm.

// Import Bootstrap functions to use inside variables values
@import "bootstrap/scss/functions";


// Color system

// Grayscale
$white:    #fff !default;
$gray-100: #f2f8fb !default;
$gray-200: #eff2f5 !default;
$gray-300: #dfe5ec !default;
$gray-400: #ccd5e0 !default;
$gray-1000: #9aa0a7 !default;
$gray-600: #808890 !default;
$gray-700: #5b646f !default;
$gray-800: #35414e !default;
$gray-900: #020109 !default;
$black:    #000 !default;

// Disable Bootstrap's default $colors map
$colors: () !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   2 !default;

// Theme colors
$primary:       #6c56e9 !default;
$secondary:     $gray-100 !default;
$success:       #2ccd80 !default;
$info:          #417dd7 !default;
$warning:       #e9ae56 !default;
$danger:        #e95656 !default;
$light:         $white !default;
$dark:          $gray-900 !default;

$primary-text-emphasis:       darken($primary, 10%) !default;
$secondary-text-emphasis:     $gray-700 !default;
$success-text-emphasis:       darken($success, 10%) !default;
$info-text-emphasis:          darken($info, 10%) !default;
$warning-text-emphasis:       darken($warning, 10%) !default;
$danger-text-emphasis:        darken($danger, 10%) !default;
$light-text-emphasis:         $gray-700 !default;
$dark-text-emphasis:          $gray-900 !default;

$primary-bg-subtle:           tint-color($primary, 90%) !default;
$secondary-bg-subtle:         $white !default;
$success-bg-subtle:           tint-color($success, 90%) !default;
$info-bg-subtle:              tint-color($info, 90%) !default;
$warning-bg-subtle:           tint-color($warning, 90%) !default;
$danger-bg-subtle:            tint-color($danger, 90%) !default;
$light-bg-subtle:             $white !default;
$dark-bg-subtle:              tint-color($dark, 92%) !default;

$primary-border-subtle:       tint-color($primary, 80%) !default;
$secondary-border-subtle:     $gray-200 !default;
$success-border-subtle:       tint-color($success, 80%) !default;
$info-border-subtle:          tint-color($info, 80%) !default;
$warning-border-subtle:       tint-color($warning, 80%) !default;
$danger-border-subtle:        tint-color($danger, 80%) !default;
$light-border-subtle:         $gray-200 !default;
$dark-border-subtle:          $gray-200 !default;

// User selection color
$user-selection-color:        rgba($gray-900, .1) !default;


// Options
//
// Modifying Bootstrap global options

$enable-caret:                false !default;
$enable-chevron:              true !default;
$enable-shadows:              true !default;
$enable-negative-margins:     true !default;
$enable-dark-mode:            true !default;


// Prefix for CSS variables and icons

$prefix:                      fp- !default;
$icon-prefix:                 fi- !default;


// Spacing

$spacer:                      1rem !default;


// Body
// Settings for the `<body>` element.

$body-color:                  $gray-800 !default;
$body-bg:                     $white !default;

$body-secondary-color:        $gray-600 !default;
$body-secondary-bg:           $gray-100 !default;

$body-tertiary-color:         $gray-400 !default;
$body-tertiary-bg:            $gray-200 !default;

$body-emphasis-color:         $black !default;


// Override Bootstrap's links

$link-color:                  $primary !default;
$link-shade-percentage:       0% !default;
$link-decoration:             none !default;
$link-hover-decoration:       underline !default;


// Paragraphs

$paragraph-margin-bottom:     $spacer * 1.5 !default;


// Grid breakpoints
//
// Modifying default Bootstrap's $grid-breakpoints map

$grid-breakpoints: (
  xs: 0,
  sm: 500px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;


// Grid containers
//
// Modifying default Bootstrap's $container-max-widths map (max-width of .container)
$container-max-widths: (
  sm: 95%,
  md: 95%,
  lg: 95%,
  xl: 95%,
  xxl: 1320px
) !default;


// Components
//
// Define common padding, border radius sizes, shadows and more.

$border-width:                1px !default;
$border-color:                $gray-400 !default;

$border-radius:               .5rem !default;
$border-radius-xs:            calc(var(--#{$prefix}border-radius) * .5) !default;
$border-radius-sm:            calc(var(--#{$prefix}border-radius) * .75) !default;
$border-radius-lg:            calc(var(--#{$prefix}border-radius) * 1.5) !default;
$border-radius-xl:            calc(var(--#{$prefix}border-radius) * 2) !default;
$border-radius-xxl:           calc(var(--#{$prefix}border-radius) * 2.5) !default;
$border-radius-pill:          50rem !default;

$box-shadow:                  0 .5rem 2rem -.25rem rgba(#676f7b, .1) !default;
$box-shadow-sm:               0 .375rem 1.5rem rgba(#676f7b, .06) !default;
$box-shadow-lg:               0 1.125rem 3rem -.375rem rgba(#676f7b, .12) !default;
$box-shadow-inset:            unset !default;

$component-color:             $gray-700 !default;
$component-hover-color:       $gray-900 !default;
$component-hover-bg:          $white !default;
$component-active-color:      $gray-900 !default;
$component-active-bg:         $gray-100 !default;
$component-disabled-color:    $gray-400 !default;

$focus-ring-width:            .25rem !default;
$focus-ring-opacity:          .1 !default;
$focus-ring-color:            rgba($gray-900, $focus-ring-opacity) !default;
$focus-ring-blur:             0 !default;
$focus-ring-box-shadow:       0 0 $focus-ring-blur $focus-ring-width var(--#{$prefix}focus-ring-color) !default;

$chevron-width:               .45em !default;
$chevron-spacing:             $chevron-width * .875 !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      "Roboto", sans-serif !default;

$font-size-root:              1rem !default;
$font-size-base:              $font-size-root !default;
$font-size-xl:                $font-size-base * 1.25 !default;
$font-size-lg:                $font-size-base * 1.125 !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-size-xs:                $font-size-base * .75 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.375 !default;
$h6-font-size:                $font-size-base !default;

// Modifying default Bootstrap's $font-sizes map
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  "xl": $font-size-xl,
  "lg": $font-size-lg,
  "base": $font-size-base,
  "sm": $font-size-sm,
  "xs": $font-size-xs
) !default;

$headings-margin-bottom:      $spacer * 1.5 !default;
$headings-font-family:        "Barlow Semi Condensed", sans-serif !default;
$headings-font-weight:        $font-weight-semibold !default;
$headings-color:              $gray-900 !default;

$h1-line-height:              1.2 !default;
$h2-line-height:              1.25 !default;
$h3-line-height:              1.3 !default;
$h4-line-height:              1.35 !default;
$h5-line-height:              1.4 !default;
$h6-line-height:              1.45 !default;

// Modifying default Bootstrap's $display-font-sizes map
$display-font-sizes: (
  1: $font-size-base * 5,
  2: $font-size-base * 4.5,
  3: $font-size-base * 4,
  4: $font-size-base * 3.5,
  5: $font-size-base * 3.5,
  6: $font-size-base * 3.5
) !default;

$display-font-weight:         $font-weight-semibold !default;
$display-line-height:         1.4 !default;

$lead-font-size:              $font-size-base * 1.375 !default;
$lead-font-weight:            $font-weight-normal !default;

$blockquote-margin-y:         $spacer * 1.5 !default;
$blockquote-color:            var(--#{$prefix}heading-color) !default;
$blockquote-font-size:        $font-size-xl !default;
$blockquote-font-weight:      $font-weight-medium !default;
$blockquote-footer-color:     var(--#{$prefix}body-color) !default;
$blockquote-footer-font-size: $font-size-sm !default;

$hr-margin-y:                 $spacer * 1.5 !default;
$hr-color:                    var(--#{$prefix}border-color) !default;
$hr-opacity:                  1 !default;

$legend-margin-bottom:        $spacer !default;
$legend-font-size:            1.125rem !default;
$legend-color:                var(--#{$prefix}heading-color) !default;
$legend-font-weight:          $headings-font-weight !default;

$dt-font-weight:              $font-weight-semibold !default;
$dt-color:                    var(--#{$prefix}heading-color) !default;

$list-inline-padding:         $spacer !default;


// Buttons + Forms

$input-btn-padding-y:                 .5625rem !default;
$input-btn-padding-x:                 1rem !default;
$input-btn-font-size:                 $font-size-sm !default;

$input-btn-padding-y-sm:              .4rem !default;
$input-btn-padding-x-sm:              .75rem !default;
$input-btn-font-size-sm:              $font-size-xs !default;

$input-btn-padding-y-lg:              .835rem !default;
$input-btn-padding-x-lg:              1rem !default;
$input-btn-font-size-lg:              $font-size-base !default;

$input-btn-focus-box-shadow:          unset !default;
$input-btn-focus-width:               0 !default;


// Buttons

$btn-color:                           var(--#{$prefix}component-color) !default;
$btn-white-space:                     nowrap !default;
$btn-text-transform:                  none !default;

$btn-padding-x:                       1.5rem !default;
$btn-padding-x-sm:                    .75rem !default;
$btn-padding-x-lg:                    2rem !default;

$btn-font-size:                       $font-size-base * .875 !default;
$btn-font-size-sm:                    $font-size-base * .75 !default;
$btn-font-size-lg:                    $font-size-base !default;

$btn-font-weight:                     $font-weight-medium !default;
$btn-disabled-opacity:                .6 !default;

$btn-link-color:                      $primary !default;
$btn-link-decoration:                 none !default;
$btn-link-hover-decoration:           none !default;
$btn-link-disabled-color:             var(--#{$prefix}component-disabled-color) !default;

$btn-border-radius:                   var(--#{$prefix}border-radius-xs) !default;
$btn-border-radius-sm:                var(--#{$prefix}border-radius-xs) !default;
$btn-border-radius-lg:                var(--#{$prefix}border-radius-xs) !default;

$btn-box-shadow:                      none !default;
$btn-active-box-shadow:               none !default;

$btn-transition:                      color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out !default;


// Icon buttons

$btn-icon-size:                       $font-size-base * 2.5 !default;
$btn-icon-font-size:                  $font-size-base * 1.125 !default;
$btn-icon-size-sm:                    $font-size-base * 2.25 !default;
$btn-icon-font-size-sm:               $font-size-base * .875 !default;
$btn-icon-size-lg:                    $font-size-base * 3.25 !default;
$btn-icon-font-size-lg:               $font-size-base * 1.25 !default;


// Scroll to top button

$btn-scroll-top-size:                 2.75rem !default;
$btn-scroll-top-icon-font-size:       $font-size-base * 1.5 !default;
$btn-scroll-top-bg:                   rgba($gray-900, .2) !default;
$btn-scroll-top-hover-bg:             rgba($white, .1) !default;
$btn-scroll-top-color:                $white !default;
$btn-scroll-top-hover-color:          $white !default;
$btn-scroll-top-transition:           transform .3s ease-in-out, opacity .3s, background-color .25s ease-in-out !default;


// Forms

$input-padding-x:                     0 !default;
$input-padding-x-sm:                  0 !default;
$input-padding-x-lg:                  0 !default;
$input-font-size:                     $font-size-base !default;
$input-color:                         $white !default;
$input-bg:                            transparent !default;
$input-disabled-bg:                   transparent !default;
$input-border-radius:                 0 !default;
$input-border-radius-sm:              0 !default;
$input-border-radius-lg:              0 !default;
$input-border-color:                  rgba($white, .3) !default;
$input-focus-border-color:            $white !default;
$input-box-shadow:                    0 !default;
$input-focus-box-shadow:              0 !default;
$form-select-box-shadow:              0 !default;


// Cards

$card-spacer-y:                       $spacer * 1.5 !default;
$card-spacer-x:                       $spacer * 1.5 !default;
$card-cap-padding-y:                  $card-spacer-y !default;
$card-cap-padding-x:                  $card-spacer-x !default;
$card-title-spacer-y:                 $card-spacer-y * .5 !default;
$card-title-color:                    var(--#{$prefix}heading-color) !default;
$card-transition:                     transform .25s ease-in-out, border-color .25s ease-in-out, background-color .25s ease-in-out, box-shadow .25s ease-in-out !default;

// Card shape
$card-shape-border-width:             $border-width !default;
$card-shape-border-color:             $border-color !default;
$card-shape-border-opacity:           1 !default;
$card-shape-bg:                       $white !default;


// Accordion

$accordion-padding-y:                 1.5rem !default;
$accordion-padding-x:                 0 !default;
$accordion-bg:                        transparent !default;
$accordion-border-radius:             0 !default;
$accordion-inner-border-radius:       0 !default;

$accordion-body-padding-y:            0 !default;
$accordion-body-padding-x:            0 !default;
$accordion-body-font-size:            $font-size-sm !default;

$accordion-button-color:              var(--#{$prefix}heading-color) !default;
$accordion-button-font-size:          $font-size-base !default;
$accordion-button-font-weight:        $headings-font-weight !default;
$accordion-button-active-color:       $accordion-button-color !default;
$accordion-button-active-bg:          transparent !default;
$accordion-transition:                none !default;

$accordion-icon-width:                1em !default;
$accordion-icon-color:                $gray-900 !default;
$accordion-icon-active-color:         $gray-900 !default;
$accordion-button-icon:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color}'><path d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/></svg>") !default;
$accordion-button-active-icon:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color}'><path d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/></svg>") !default;
$accordion-button-icon-alt:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color}'><path d='M12 4.125c.483 0 .875.392.875.875v14c0 .483-.392.875-.875.875s-.875-.392-.875-.875V5c0-.483.392-.875.875-.875z'/><path d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/></svg>") !default;
$accordion-button-active-icon-alt:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color}'><path d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/></svg>") !default;


// Carousel

$carousel-bullet-size:                .375rem !default;
$carousel-bullet-border-color:        var(--#{$prefix}gray-600) !default;
$carousel-bullet-border-width:        var(--#{$prefix}border-width) !default;
$carousel-bullet-border-radius:       50% !default;
$carousel-bullet-spacer:              $spacer * .875 !default;
$carousel-bullet-transition:          transform .2s ease-in-out, border-color .2s ease-in-out, background-color .2s ease-in-out !default;
$carousel-bullet-bg:                  transparent !default;
$carousel-bullet-active-border-color: var(--#{$prefix}primary) !default;
$carousel-bullet-active-bg:           var(--#{$prefix}primary) !default;
$carousel-light-bullet-border-color:        rgba($white, .6) !default;
$carousel-light-bullet-bg:                  rgba($white, .6) !default;
$carousel-light-bullet-active-border-color: $white !default;
$carousel-light-bullet-active-bg:           $white !default;

// Thumbnails
$carousel-thumbnail-width:                  12.25rem !default;
$carousel-thumbnail-border-width:           0 !default;
$carousel-thumbnail-border-radius:          var(--#{$prefix}border-radius-xs) !default;
$carousel-thumbnail-border-color:           var(--#{$prefix}border-color) !default;
$carousel-thumbnail-opacity:                .5 !default;
$carousel-thumbnail-hover-opacity:          .8 !default;
$carousel-thumbnail-active-border-color:    var(--#{$prefix}primary) !default;
$carousel-thumbnail-active-opacity:         1 !default;
$carousel-thumbnail-spacer:                 $spacer * 1.5 !default;


// Navs

$nav-link-padding-y:                  .5rem !default;
$nav-link-padding-x:                  1.25rem !default;
$nav-link-font-size:                  $font-size-sm !default;
$nav-link-font-weight:                $font-weight-medium !default;
$nav-link-line-height:                1.375rem !default;
$nav-link-color:                      var(--#{$prefix}component-color) !default;
$nav-link-hover-color:                var(--#{$prefix}component-hover-color) !default;
$nav-link-active-color:               var(--#{$prefix}component-active-color) !default;
$nav-link-disabled-color:             var(--#{$prefix}component-disabled-color) !default;
$nav-link-transition:                 color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out !default;


// Navbar

$navbar-padding-y:                    $spacer * .875 !default;
$navbar-nav-link-padding-x:           $spacer * 1.25 !default;
$navbar-dark-color:                   rgba($white, .8) !default;
$navbar-dark-hover-color:             $white !default;
$navbar-dark-active-color:            $white !default;
$navbar-dark-disabled-color:          rgba($white, .5) !default;

// Navbar Toggler
$navbar-toggler-padding-y:            10px !default;
$navbar-toggler-padding-x:            0 !default;
$navbar-toggler-border-radius:        $border-radius !default;
$navbar-toggler-bar-width:            22px !default;
$navbar-toggler-bar-height:           2px !default;
$navbar-toggler-bar-spacing:          4px !default;
$navbar-toggler-bar-border-radius:    $border-radius !default;


// Icon-box

$icon-box-size:                       4rem !default;
$icon-box-font-size:                  $font-size-base * 1.5 !default;
$icon-box-border-width:               $border-width !default;
$icon-box-border-radius:              $border-radius !default;
$icon-box-border-color:               $dark-text-emphasis !default;
$icon-box-border-opacity:             1 !default;
$icon-box-color:                      $dark-text-emphasis !default;
$icon-box-bg:                         $body-secondary-bg !default;


// Badges

$badge-font-size:                     .75em !default;
$badge-font-weight:                   $font-weight-normal !default;
$badge-padding-y:                     .425em !default;
$badge-padding-x:                     .87em !default;
$badge-border-radius:                 var(--#{$prefix}border-radius-xs) !default;


// Gallery

$gallery-indicator-size:              3.25rem !default;

$gallery-image-indicator-color:       $white !default;
$gallery-image-indicator-font-size:   $font-size-base * 2.5 !default;

$gallery-video-indicator-bg:          $white !default;
$gallery-video-indicator-color:       $gray-900 !default;
$gallery-video-indicator-font-size:   $font-size-base * 1.325 !default;
$gallery-video-indicator-box-shadow:  0 .375rem 1rem -.25rem rgba($black, .2) !default;

$gallery-overlay-bg:                  rgba($gray-900, .4) !default;
$gallery-caption-color:               $white !default;
$gallery-caption-font-size:           $font-size-sm !default;
