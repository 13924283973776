// Icon box

.icon-box {
  --#{$prefix}icon-box-size: #{$icon-box-size};
  --#{$prefix}icon-box-font-size: #{$icon-box-font-size};
  --#{$prefix}icon-box-border-width: #{$icon-box-border-width};
  --#{$prefix}icon-box-border-color: #{$icon-box-border-color};
  --#{$prefix}icon-box-border-opacity: #{$icon-box-border-opacity};
  --#{$prefix}icon-box-border-radius: #{$icon-box-border-radius};
  --#{$prefix}icon-box-color: #{$icon-box-color};
  --#{$prefix}icon-box-bg: #{$icon-box-bg};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--#{$prefix}icon-box-size);
  height: var(--#{$prefix}icon-box-size);
  padding: var(--#{$prefix}icon-box-border-width);
  overflow: hidden;
  font-size: var(--#{$prefix}icon-box-font-size);
  color: var(--#{$prefix}icon-box-color);
  border-radius: var(--#{$prefix}icon-box-border-radius);

  // Shape
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background: var(--#{$prefix}icon-box-border-color);
    opacity: var(--#{$prefix}icon-box-border-opacity);
  }

  // Icon
  [class*=" #{$icon-prefix}"],
  [class^="#{$icon-prefix}"] {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--#{$prefix}icon-box-bg);
    border-radius: inherit;
  }
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .icon-box {
      --#{$prefix}icon-box-border-color: #{$icon-box-border-color-dark};
      --#{$prefix}icon-box-border-opacity: #{$icon-box-border-opacity-dark};
      --#{$prefix}icon-box-color: #{$icon-box-color-dark};
      --#{$prefix}icon-box-bg: #{$icon-box-bg-dark};
    }
  }
}
