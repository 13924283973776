// Card

.card-shape {
  --#{$prefix}card-shape-border-width: #{$card-shape-border-width};
  --#{$prefix}card-shape-border-color: #{$card-shape-border-color};
  --#{$prefix}card-shape-border-opacity: #{$card-shape-border-opacity};
  --#{$prefix}card-shape-bg: #{$card-shape-bg};

  position: relative;
  padding: var(--#{$prefix}card-shape-border-width);
  overflow: hidden;
  border: initial;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    background: var(--#{$prefix}card-shape-border-color);
    border-radius: inherit;
    opacity: var(--#{$prefix}card-shape-border-opacity);
  }

  .card-body {
    position: relative;
    background-color: var(--#{$prefix}card-shape-bg);
    border-radius: inherit;
  }
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .card {
      --#{$prefix}card-bg: #{$card-bg-dark};
      --#{$prefix}card-shape-border-color: #{$card-shape-border-color-dark};
      --#{$prefix}card-shape-border-opacity: #{$card-shape-border-opacity-dark};
      --#{$prefix}card-shape-bg: #{$card-shape-bg-dark};

      backdrop-filter: blur(20px);
    }
  }
}
