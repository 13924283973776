// Typography related styles

// Headings

h1,
.h1 {
  line-height: $h1-line-height;
}

h2,
.h2 {
  line-height: $h2-line-height;
}

h3,
.h3 {
  line-height: $h3-line-height;
}

h4,
.h4 {
  line-height: $h4-line-height;
}

h5,
.h5 {
  line-height: $h4-line-height;
}

h6,
.h6 {
  line-height: $h4-line-height;
}


// Link inside headings

// stylelint-disable stylistic/selector-list-comma-newline-after
h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  > a {
    color: var(--#{$prefix}heading-link-color);
    text-decoration: none;
    transition: color .2s ease-in-out;
    &:hover {
      color: var(--#{$prefix}heading-link-hover-color);
    }
  }
}
// stylelint-enable stylistic/selector-list-comma-newline-after
